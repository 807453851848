/**
 *
 * Glyph definitions for the logo component.
 * Inspired / derived from https://github.com/weng-lab/logojs-package
 *
 */
import React from "react";

//
// typing
//
type ILetterToGlyphHM = {
  [letter in AvailableGlyphs]: (
    pathProps: { [propKey: string]: string }
  ) => JSX.Element
}

//
// list of available fonts
//
export class LogoFonts  {
  static readonly HELVETICA = new LogoFonts(
    "HELVETICA",
    "Helvetica",
  );
  static readonly HELVETICA_BOLD = new LogoFonts(
    "HELVETICA_BOLD",
    "Helvetica Bold",
  );
  static readonly ROBOTO_MONO_MEDIUM = new LogoFonts(
    "ROBOTO_MONO_MEDIUM",
    "Roboto Mono Medium",
  );
  static readonly DEFAULT = LogoFonts.HELVETICA_BOLD;

  static list = [
    LogoFonts.HELVETICA,
    LogoFonts.HELVETICA_BOLD,
    LogoFonts.ROBOTO_MONO_MEDIUM,
  ];

  static fromKey(key: string) {
    return LogoFonts.list.find((lf) => {
      return lf.key === key;
    });
  }

  private constructor(
    public readonly key: string,
    public readonly description: string,
  ) {}
}


//
// GlyphFactory class
//
export class GlyphFactory {
  //singleton instance - unavailable to caller
  private static singleton: undefined | GlyphFactory;
  
  //entry point for caller
  public static glyphFromChar(
    letter: AvailableGlyphs, 
    font: LogoFonts
  ) {
    if (!GlyphFactory.singleton) {
      GlyphFactory.singleton = new GlyphFactory();
    }
    let glyphsForFont = GlyphFactory.singleton.fontToGlyphGetters.get(font);
    if (!glyphsForFont){
      console.warn(
        `WARNING: Glyph font "${font.key}" not implemented. Reverting
         to default font (${LogoFonts.DEFAULT.key})`
      );
      glyphsForFont = GlyphFactory.singleton.fontToGlyphGetters.get(
        LogoFonts.DEFAULT
      )!;
    }
    return glyphsForFont[letter];
  }

  //munge the draw svg "d" values for each string into path elements
  private fontToGlyphGetters = [...GlyphFactory.GLYPH_PATH_D].reduce((acc, [font, dStrs])=>{
    acc.set(
      font, 
      (Object.entries(dStrs) as Array<[
        AvailableGlyphs, string
      ]>).reduce((acc2, [char, dStr]) => {
        acc2[char as AvailableGlyphs] = (
          pathProps: { [key: string]: string }
        ) => (
          <path
            {...pathProps}          
            d={dStr}
          />
        )
        return acc2;
      }, {} as ILetterToGlyphHM)
    )
    return acc;
  }, new Map<LogoFonts, ILetterToGlyphHM>());


  private static GLYPH_PATH_D = new Map<LogoFonts, {[letter in AvailableGlyphs]: string}>([
    [
      LogoFonts.HELVETICA, {
        A: `M43,0h15.4l36.3,100H79.8L69.7,70H30.1l-10.8,30H5.4L43,0z M65.3,59L50.1,14.8L34,59H65.3z`,
        B: `M11.2,0h43.2c11.8,0,20.2,3.5,25.1,10.5c2.9,4.1,4.4,8.9,4.4,14.3c0,6.3-1.8,11.5-5.4,15.5
           c-1.9,2.1-4.5,4.1-8,5.9c5.1,2,9,4.2,11.5,6.6c4.5,4.4,6.7,10.4,6.7,18c0,6.4-2,12.3-6.1,17.5C76.6,96.1,67,100,53.7,100H11.2V0z
            M49.4,42.3c5.8,0,10.3-0.8,13.5-2.4c5-2.5,7.6-7,7.6-13.5c0-6.5-2.7-10.9-8-13.2c-3-1.3-7.5-1.9-13.5-1.9H24.5v31H49.4z M54,88.4
           c8.4,0,14.4-2.4,17.9-7.2c2.2-3,3.4-6.7,3.4-11c0-7.3-3.3-12.2-9.8-14.8c-3.5-1.4-8.1-2.1-13.8-2.1H24.5v35.2H54z`,
        C: `M79.8,9.5C86.3,15.8,90,23,90.7,31.1H78.2c-1.4-6.1-4.2-11-8.3-14.5c-4.2-3.6-10-5.4-17.5-5.4
            c-9.2,0-16.6,3.3-22.2,9.9c-5.6,6.6-8.5,16.6-8.5,30.2c0,11.1,2.5,20.1,7.6,27.1c5.1,6.9,12.7,10.4,22.8,10.4
            c9.3,0,16.4-3.6,21.3-10.9c2.6-3.8,4.5-8.9,5.8-15.1h12.5c-1.1,10-4.8,18.4-11,25.1C73.2,95.9,63.1,100,50.5,100
            c-10.9,0-20.1-3.3-27.5-10C13.3,81.1,8.4,67.5,8.4,49.1c0-14,3.7-25.5,11-34.5C27.3,4.9,38.2,0,52.1,0C64,0,73.2,3.2,79.8,9.5z`,
        D: `M9.2,0h40c13.6,0,24.1,4.9,31.6,14.6c6.7,8.8,10,20.1,10,33.8c0,10.6-2,20.2-5.9,28.8
           C77.9,92.4,66,100,49.1,100H9.2V0z M46.5,88.4c4.5,0,8.2-0.5,11.1-1.4c5.1-1.8,9.4-5.2,12.6-10.2c2.6-4,4.5-9.2,5.7-15.5
           c0.7-3.8,1-7.3,1-10.5c0-12.4-2.4-22-7.2-28.9c-4.8-6.9-12.6-10.3-23.3-10.3H22.9v76.8H46.5z`,
        E: `M13.2,0h72.6v12.3H26.4v30.4h54.9v11.6H26.4v33.9h60.4V100H13.2V0z`,
        F: `M15.3,0h69.4v12.3H28.9v30.4h49.1v11.9H28.9V100H15.3V0z`,
        G: `M74.1,5.2c9.3,5,15,13.6,17.1,26.1H78.5c-1.5-6.9-4.7-12-9.4-15.2c-4.8-3.2-10.8-4.8-18.1-4.8
            c-8.6,0-15.9,3.3-21.8,9.9c-5.9,6.6-8.8,16.4-8.8,29.5c0,11.3,2.4,20.5,7.3,27.6c4.9,7.1,12.8,10.6,23.8,10.6
            c8.4,0,15.4-2.5,20.9-7.5c5.5-5,8.3-13,8.5-24.2H51.1V46.6h41.7v50.8h-8.3l-3.1-12.2c-4.3,4.8-8.1,8.1-11.4,10
            c-5.6,3.2-12.6,4.8-21.2,4.8c-11.1,0-20.6-3.6-28.6-10.9c-8.7-9.1-13-21.7-13-37.6c0-15.9,4.2-28.6,12.7-38C28,4.5,38.5,0,51.3,0
            C60.1,0,67.7,1.7,74.1,5.2z`,
        H: `M10.6,0h13.7v41.3h51.4V0h13.7v100H75.7V53.2H24.3V100H10.6V0z`,
        I: `M43.2,0h13.7v100H43.2V0z`,
        J: `M94.6,68.4c0,8.2-1.9,14.6-5.8,19.2C81.6,95.8,67.9,100,47.7,100C36,100,26,98,17.8,94.1
            c-8.3-4-12.4-11-12.4-21.1v-7h19.8v7c0,5.3,1.9,9.3,5.7,12c3.8,2.7,9.7,4,17.7,4c11.2,0,18.6-2.4,22.1-7.2c2.1-3,3.2-8.6,3.2-16.8V0
            h20.9V68.4z`,
        K: `M8.5,0h13.2v48.9L71.7,0h18.9L47.9,40.4L91.5,100H73.8L38,49.8L21.7,65.1V100H8.5V0z`,
        L: `M18.1,0h13.5v88.1h50.2V100H18.1V0z`,
        M: `M2,0h19.4l28.8,84.5L78.7,0H98v100H85.1V41c0-2,0-5.4,0.1-10.1c0.1-4.7,0.1-9.8,0.1-15.2L56.8,100H43.4
            L14.5,15.7v3.1c0,2.5,0.1,6.2,0.2,11.2c0.1,5,0.2,8.7,0.2,11.1v59H2V0z`,
        N: `M10.6,0h16l49.9,80.8V0h12.9v100H74.2L23.6,19.3V100H10.6V0z`,
        O: `M86.7,16.1c6.3,8.4,9.4,19.1,9.4,32.2c0,14.1-3.6,25.9-10.8,35.2c-8.4,11-20.4,16.5-36,16.5
            c-14.6,0-26-4.8-34.3-14.4c-7.4-9.3-11.1-21-11.1-35.2c0-12.8,3.2-23.8,9.5-32.9C21.6,5.8,33.6,0,49.6,0C66.3,0,78.7,5.4,86.7,16.1z
            M75.4,76.4c5-8.1,7.6-17.4,7.6-28c0-11.1-2.9-20.1-8.7-26.9c-5.8-6.8-13.8-10.2-23.9-10.2c-9.8,0-17.8,3.4-24,10.1
            s-9.3,16.6-9.3,29.8c0,10.5,2.7,19.3,8,26.5C30.4,85,39,88.6,50.9,88.6C62.2,88.6,70.4,84.5,75.4,76.4z`,
        P: `M12.8,0h44.7c8.8,0,16,2.5,21.4,7.5c5.4,5,8.2,12.1,8.2,21.1c0,7.8-2.4,14.6-7.3,20.4
            c-4.9,5.8-12.3,8.7-22.3,8.7H26.4V100H12.8V0z M65.4,13.8c-3-1.4-7.1-2.1-12.2-2.1H26.4v34.6h26.8c6,0,10.9-1.3,14.7-3.9
            c3.8-2.6,5.6-7.1,5.6-13.7C73.5,21.4,70.8,16.4,65.4,13.8z`,
        Q: `M93.2,92.6l-6.1,7.4l-14-10.6c-3.4,1.8-7,3.3-10.9,4.4s-8.2,1.7-12.8,1.7c-14,0-25-4.6-33-13.8
            c-7-8.9-10.5-20.1-10.5-33.6c0-12.2,3-22.7,9.1-31.4C22.8,5.5,34.3,0,49.7,0c16,0,27.8,5.1,35.4,15.4c6,8,9,18.2,9,30.7
            c0,5.8-0.7,11.4-2.2,16.8c-2.2,8.2-5.8,14.9-11,20L93.2,92.6z M58.1,84.1c2.2-0.4,4.1-1,5.8-2.1l-10-7.7l6.1-7.6l11.8,9.2
            c3.7-4.3,6.3-9.1,7.6-14.3c1.3-5.3,2-10.4,2-15.2c0-10.6-2.8-19.2-8.3-25.7C67.4,14.2,59.8,11,50.3,11c-9.8,0-17.5,3.1-23.2,9.3
            c-5.7,6.2-8.6,15.8-8.6,28.7c0,10.9,2.7,19.5,8.2,26c5.5,6.4,13.5,9.7,24.1,9.7C53.4,84.6,55.8,84.4,58.1,84.1z`,
        R: `M9.2,0h45.5c7.5,0,13.7,1.1,18.5,3.3C82.4,7.6,87,15.4,87,26.9c0,6-1.2,10.9-3.7,14.7
            c-2.5,3.8-5.9,6.9-10.4,9.2c3.9,1.6,6.8,3.7,8.8,6.3c2,2.6,3.1,6.8,3.3,12.6l0.5,13.4c0.1,3.8,0.5,6.6,1,8.5
            c0.8,3.2,2.3,5.2,4.4,6.1v2.2H74.2c-0.5-0.9-0.8-2-1.1-3.3c-0.3-1.4-0.5-4-0.7-7.9L71.6,72c-0.3-6.5-2.7-10.9-7.1-13.1
            c-2.5-1.2-6.5-1.8-11.8-1.8h-30V100H9.2L9.2,0L9.2,0z M53.2,45.8c6.2,0,11.1-1.3,14.7-3.8s5.4-7.1,5.4-13.8c0-7.1-2.5-12-7.5-14.6
            c-2.7-1.4-6.3-2-10.8-2H22.8v34.2L53.2,45.8L53.2,45.8z`,
        S: `M25.2,66.6c0.3,5.4,1.5,9.7,3.7,13.1c4.1,6.3,11.3,9.4,21.7,9.4c4.6,0,8.9-0.7,12.7-2.1
            c7.4-2.7,11.1-7.4,11.1-14.3c0-5.2-1.6-8.8-4.7-11c-3.2-2.2-8.1-4-14.9-5.6l-12.4-2.9c-8.1-1.9-13.9-4-17.3-6.3
            c-5.8-4-8.8-9.9-8.8-17.8c0-8.5,2.9-15.5,8.6-21C30.6,2.7,38.8,0,49.3,0C59,0,67.2,2.4,73.9,7.2c6.8,4.8,10.1,12.5,10.1,23H72
            c-0.6-5.1-1.9-9-4-11.7c-3.8-4.9-10.1-7.4-19.1-7.4c-7.3,0-12.5,1.6-15.7,4.8c-3.2,3.2-4.8,6.9-4.8,11.1c0,4.6,1.9,8,5.6,10.2
            c2.5,1.4,8,3.1,16.7,5.2l12.9,3c6.2,1.5,11,3.5,14.4,6c5.8,4.4,8.8,10.9,8.8,19.3c0,10.5-3.7,18-11.2,22.5c-7.5,4.5-16.1,6.8-26,6.8
            c-11.5,0-20.5-3-27-9c-6.5-6-9.7-14.1-9.6-24.3H25.2z`,
        T: `M90.5,0v11.9H56.8V100H43.2V11.9H9.5V0H90.5z`,
        U: `M24.8,0v60.2c0,7.1,1.3,13,4,17.6c4,7.1,10.6,10.6,20.1,10.6c11.3,0,18.9-3.8,23-11.5c2.2-4.2,3.3-9.7,3.3-16.8
            V0h13.3v54.7c0,12-1.6,21.2-4.9,27.7C77.7,94.1,66.5,100,50,100c-16.5,0-27.7-5.9-33.6-17.6c-3.2-6.5-4.9-15.7-4.9-27.7V0H24.8z`,
        V: `M21.3,0l28.7,85.2L78.5,0h15.2L57.1,100H42.8L6.4,0H21.3z`,
        W: `M11.6,0l14.8,81.2L44.2,0h11.5l17.8,81.4L88.3,0H100L79.3,100H68.2L50,17.1L31.8,100H20.5L0,0H11.6z`,
        X: `M22.7,100H6.5l35.4-51.3L8.7,0h16.9l25.2,38.3L75.8,0h16.1L58.7,48.7L93.5,100H76.8L50.1,58.9L22.7,100z`,
        Y: `M5.4,0h15.8l28.7,48.1L78.7,0h15.9L56.7,59.7V100H43.2V59.7L5.4,0z`,
        Z: `M10.5,88.8L72,11.9H15V0h74.5v11.6L27.6,88.1h61.9V100h-79V88.8z`,
      } 
    ],
    [ LogoFonts.ROBOTO_MONO_MEDIUM, {
        A:`M65.8,76.8H34.5L27.6,100h-17L43.1,0h14.5l31.9,100h-17L65.8,76.8z M38.7,62.7h22.9L50.3,23.5L38.7,62.7z`,
        B:`M15.6,100V0h31.9c4.8,0,9.3,0.6,13.5,1.6s7.9,2.7,11,4.9s5.6,5,7.4,8.4S82.1,22.4,82,27c0,4.9-1.4,9.2-4.1,12.7
            s-6.3,6.2-10.9,8.1c2.8,0.8,5.3,1.9,7.5,3.3s4,3.2,5.5,5.2s2.6,4.2,3.4,6.7c0.8,2.4,1.2,5,1.2,7.8c0,4.8-0.9,9-2.6,12.6
            s-4.2,6.6-7.2,9.1c-3.1,2.4-6.8,4.3-11,5.5c-4.2,1.3-8.8,1.9-13.7,2H15.6z M32.2,42.1H48c2.5,0,4.8-0.4,6.9-1
            c2.1-0.6,4-1.5,5.5-2.7s2.8-2.7,3.7-4.5c0.9-1.8,1.4-3.8,1.4-6.1c0-2.5-0.4-4.7-1.3-6.5c-0.9-1.8-2.1-3.3-3.7-4.4
            c-1.6-1.1-3.4-2-5.6-2.5c-2.2-0.5-4.5-0.8-7.1-0.9H32.2V42.1z M32.2,54.7v31.9h18c2.6,0,5-0.4,7.1-1.2s4-1.8,5.6-3.1
            c1.6-1.3,2.8-3,3.7-4.9c0.9-1.9,1.3-4.1,1.3-6.5c0-2.5-0.4-4.8-1.2-6.8c-0.8-2-1.9-3.7-3.4-5c-1.5-1.3-3.3-2.4-5.4-3.2
            c-2.1-0.8-4.5-1.2-7.1-1.2C50.8,54.7,32.2,54.7,32.2,54.7z`,
        C:`M84.8,68.8c-0.4,4.7-1.6,8.9-3.3,12.8c-1.8,3.8-4.1,7.1-7.1,9.9c-2.9,2.7-6.4,4.9-10.4,6.3s-8.4,2.2-13.3,2.2
            c-5.9,0-11.1-1.1-15.5-3.4s-8.1-5.4-11-9.3s-5.1-8.5-6.6-13.8c-1.5-5.3-2.3-10.9-2.3-16.8V43.4c0-5.9,0.8-11.5,2.3-16.8
            s3.8-9.9,6.7-13.8s6.6-7.1,11-9.3S44.8,0,50.7,0c5.1,0,9.7,0.8,13.7,2.3s7.4,3.6,10.3,6.4c2.8,2.8,5.1,6.1,6.8,10.1
            c1.7,4,2.8,8.4,3.3,13.2h-16c-0.3-2.8-0.8-5.4-1.6-7.8s-1.9-4.3-3.4-6s-3.2-2.9-5.4-3.8c-2.2-0.9-4.7-1.3-7.6-1.3
            c-3.6,0-6.6,0.8-9.1,2.5s-4.4,3.9-5.9,6.6s-2.6,6-3.2,9.6c-0.6,3.6-1,7.5-1,11.5v13.4c0,4.2,0.3,8.1,1,11.8
            c0.6,3.7,1.7,6.9,3.2,9.6c1.5,2.8,3.4,4.9,5.9,6.5s5.5,2.4,9.1,2.4c5.6,0,9.9-1.6,12.8-4.8c2.9-3.2,4.7-7.7,5.2-13.5L84.8,68.8
            L84.8,68.8z`,
        D:`M15.5,100V0H40c6.9,0,13,1.2,18.5,3.4s10.1,5.4,13.9,9.5s6.8,8.9,8.8,14.6c2.1,5.7,3.1,11.9,3.2,18.8v7.6
            c0,6.9-1.1,13.1-3.2,18.8c-2.1,5.7-5,10.5-8.8,14.6s-8.4,7.2-13.8,9.4c-5.4,2.2-11.5,3.4-18.2,3.4L15.5,100L15.5,100z M32.3,13.3
            v73.4h8.2c4.5,0,8.5-0.9,11.9-2.4c3.4-1.6,6.2-3.8,8.5-6.7c2.3-2.9,4-6.3,5.1-10.3c1.1-4,1.7-8.5,1.8-13.5v-7.7
            c0-4.8-0.6-9.2-1.8-13.2c-1.2-4-2.8-7.4-5.1-10.3s-5.2-5.2-8.6-6.8s-7.5-2.5-12.2-2.5C40.2,13.3,32.3,13.3,32.3,13.3z`,
        E:`M75.4,55.2H34.2v31.4h48.1V100H17.7V0h64.2v13.6H34.2v28.2h41.1C75.3,41.8,75.3,55.2,75.4,55.2z`,
        F:`M76.2,57.4h-42V100H17.6V0h64.8v13.6H34.2v30.3h42V57.4z`,
        G:`M84.6,86.5c-1.6,2-3.6,3.8-5.8,5.4s-4.7,3.1-7.5,4.3c-2.8,1.2-5.8,2.1-9,2.8c-3.2,0.7-6.7,1-10.3,1c-5.8,0-11-1.1-15.5-3.4
            s-8.3-5.4-11.4-9.3s-5.5-8.6-7.1-13.9c-1.6-5.4-2.5-11.2-2.5-17.3v-12c0-6.1,0.8-11.9,2.4-17.2s3.9-10,6.9-14s6.7-7.2,11.1-9.5
            S45.2,0,50.9,0c5,0,9.5,0.7,13.5,2.1s7.4,3.4,10.2,6c2.9,2.6,5.1,5.8,6.8,9.5s2.7,7.9,3.2,12.6H68.8c-0.4-2.6-1-5-1.9-7.1
            s-2-3.9-3.4-5.3s-3.2-2.6-5.2-3.4c-2-0.8-4.4-1.2-7.2-1.2c-3.4,0-6.3,0.8-8.7,2.5s-4.4,3.9-6,6.8c-1.6,2.8-2.8,6.1-3.5,9.8
            s-1.2,7.6-1.2,11.7v12.2c0,4.1,0.5,7.9,1.2,11.6s2,7,3.7,9.8s3.8,5.1,6.5,6.8s5.8,2.6,9.5,2.6c1.5,0,3.1-0.1,4.7-0.3
            c1.6-0.2,3.1-0.5,4.6-1s2.8-1,4-1.7c1.2-0.7,2.2-1.5,2.9-2.6l0.1-19.5H51.2V49.4h33.3L84.6,86.5z`,
        H:`M83.1,100H66.9V55.4H33V100H17V0h16v41.8h34V0h16.1V100z`,
        I:`M20.2,0h59.5v13.7H58v72.7h21.6V100H20.2V86.4h21.2V13.7H20.2V0z`,
        J:`M66.4,0h16.5l0.1,68.5c-0.1,4.6-1,8.8-2.7,12.7c-1.7,3.9-4,7.2-7,10s-6.4,4.9-10.4,6.5S54.7,100,50,100
            c-4.8,0-9.2-0.7-13.1-2s-7.3-3.3-10.2-5.8c-2.9-2.5-5.1-5.7-6.8-9.5s-2.6-8-2.8-12.9h16.4c0.2,5.3,1.7,9.5,4.5,12.4
            c2.8,3,6.8,4.4,12,4.4c2.5,0,4.7-0.5,6.8-1.4s3.8-2.3,5.2-3.9c1.4-1.7,2.5-3.6,3.3-5.8s1.2-4.6,1.2-7.1V0H66.4z`,
        K:`M40.4,56.5l-10.7,12V100H13V0h16.7v46.8L38.8,35L65.1,0h20.1l-34,43.9L87,100H67.2L40.4,56.5z`,
        L:`M34.5,86.5h47.7V100H18V0h16.5V86.5z`,
        M:`M37,0l12.4,43.2L63,0h20.5v100H68.1V65.7l1-43.8L53.7,69.8H45L31.1,23.5l1,42.2V100H16.5V0H37z`,
        N:`M82.9,100H66.3L33.8,31.2L33.8,100H17V0h16.7l32.4,68.6L66.2,0h16.7V100z`,
        O:`M84.9,56.3c0,5.7-0.7,11.3-2.2,16.5c-1.5,5.3-3.7,9.9-6.6,14c-2.9,4-6.6,7.2-11,9.6c-4.4,2.4-9.5,3.6-15.4,3.6
            c-5.8,0-10.9-1.2-15.2-3.6s-7.9-5.6-10.8-9.7c-2.9-4-5-8.7-6.5-13.9C15.7,67.5,15,62,15,56.3V43.9c0-3.8,0.3-7.6,1-11.2
            c0.7-3.6,1.6-7.1,2.9-10.3c1.3-3.2,2.9-6.2,4.7-9s4.1-5.1,6.7-7.1s5.5-3.5,8.7-4.6S45.8,0,49.6,0c5.9,0,11,1.2,15.4,3.6
            c4.4,2.4,8.1,5.6,11,9.7c2.9,4.1,5.1,8.7,6.6,14s2.2,10.8,2.2,16.6v12.4H84.9z M68.8,43.7c0-3.7-0.4-7.3-1-10.9s-1.7-6.8-3.1-9.7
            c-1.4-2.9-3.4-5.1-5.8-6.9s-5.5-2.6-9.1-2.6c-3.6,0-6.6,0.9-9,2.6s-4.3,4.1-5.7,6.9s-2.4,6.1-2.9,9.7c-0.5,3.6-0.9,7.2-0.9,10.9
            v12.6c0,3.7,0.4,7.3,0.9,10.9c0.6,3.6,1.6,6.8,3,9.7c1.4,2.9,3.3,5.2,5.7,7s5.4,2.7,9,2.7c3.7,0,6.7-0.9,9.1-2.7s4.4-4.1,5.8-7
            c1.4-2.9,2.5-6.1,3.1-9.7s0.9-7.2,1-10.9L68.8,43.7L68.8,43.7z`,
        P:`M32.5,61.7V100H16V0h33.4c5,0,9.6,0.8,13.8,2.2s7.9,3.5,11,6.2c3.1,2.7,5.5,5.9,7.2,9.8C83.1,22,84,26.3,84,31.1
            c0,4.7-0.9,8.9-2.6,12.6c-1.7,3.8-4.1,7-7.2,9.6c-3.1,2.7-6.7,4.7-11,6.1c-4.2,1.4-8.8,2.2-13.8,2.2L32.5,61.7L32.5,61.7z
            M32.5,48.4h17c2.8,0,5.3-0.4,7.5-1.3c2.2-0.8,4.1-2,5.6-3.5c1.6-1.5,2.7-3.3,3.6-5.4c0.8-2.1,1.2-4.4,1.2-6.9c0-2.6-0.4-5-1.2-7.2
            s-2-4.1-3.6-5.7s-3.4-2.8-5.6-3.7c-2.2-0.9-4.7-1.3-7.5-1.4h-17V48.4z`,
        Q:`M79.2,49.4c0,2.9-0.3,5.7-0.8,8.5s-1.2,5.5-2.2,8c-0.9,2.6-2.1,5-3.6,7.3c-1.4,2.3-3.1,4.3-5,6.1l14.4,12.3l-9.2,8.4
            L56.4,85.6c-1.2,0.3-2.5,0.6-3.9,0.8c-1.3,0.2-2.7,0.3-4.2,0.3c-5.1,0-9.6-1.1-13.4-3.2c-3.8-2.1-7-5-9.5-8.5
            c-2.4-3.5-4.3-7.5-5.5-11.9c-1.2-4.4-1.9-9-1.9-13.7V38.7c0-3.4,0.3-6.6,0.9-9.9s1.4-6.3,2.5-9.1s2.5-5.5,4.2-7.9
            c1.7-2.4,3.6-4.5,5.8-6.2s4.8-3.1,7.6-4.1S45,0,48.3,0c5.2,0,9.7,1.1,13.5,3.2c3.8,2.1,7.1,5,9.6,8.5s4.5,7.7,5.7,12.4
            c1.3,4.7,1.9,9.5,2,14.6L79.2,49.4L79.2,49.4z M65.2,38.6c0-3.3-0.3-6.5-0.8-9.7c-0.5-3.2-1.4-6.1-2.7-8.6s-3-4.5-5.2-6.1
            s-4.9-2.3-8.2-2.3s-6,0.8-8.1,2.3c-2.1,1.5-3.8,3.6-5,6.1c-1.2,2.5-2.1,5.4-2.6,8.6s-0.7,6.4-0.7,9.7v10.9c0,2.9,0.2,5.8,0.7,8.8
            s1.3,5.7,2.6,8.2c1.2,2.5,2.9,4.5,5,6.1c2.1,1.6,4.8,2.4,8.1,2.4s6.1-0.8,8.2-2.4c2.2-1.6,3.9-3.6,5.2-6.1s2.2-5.2,2.7-8.2
            c0.5-3,0.8-5.9,0.8-8.8V38.6z`,
        R:`M48.3,61H31.6v39H15.1V0h31.6c5.1,0,9.9,0.7,14.2,2s8,3.1,11.2,5.6s5.6,5.6,7.3,9.4c1.7,3.8,2.6,8.2,2.6,13.2
            c0,3.4-0.4,6.5-1.3,9.2s-2.1,5.3-3.7,7.5c-1.6,2.2-3.5,4.2-5.8,5.9c-2.2,1.7-4.7,3.1-7.4,4.3l21.2,42v0.8H67.4L48.3,61z M31.6,47.7
            h15.2c2.7,0,5.3-0.4,7.6-1.2s4.3-1.9,5.9-3.3c1.7-1.4,3-3.2,3.9-5.3c0.9-2.1,1.4-4.5,1.4-7.2c0-2.8-0.4-5.3-1.3-7.4
            c-0.9-2.1-2.1-4-3.7-5.4c-1.6-1.5-3.6-2.6-5.9-3.3c-2.3-0.8-4.9-1.2-7.8-1.2H31.6V47.7z`,
        S:`M68.8,73.6c0-2.3-0.5-4.4-1.4-6.1c-0.9-1.7-2.3-3.3-4.1-4.6c-1.8-1.3-3.9-2.5-6.3-3.5s-5.1-1.9-7.9-2.8
            c-3.8-1.1-7.6-2.5-11.3-4.1s-7-3.7-10-6.1s-5.3-5.2-7.1-8.4c-1.9-3.2-2.8-6.9-2.8-11s0.9-8,2.8-11.3c1.9-3.3,4.2-6.1,7.3-8.5
            s6.6-4.1,10.6-5.4S46.7,0,51.1,0c4.7,0,9.1,0.7,13.1,2.2s7.6,3.5,10.7,6.1c3.1,2.6,5.5,5.7,7.3,9.4c1.8,3.6,2.7,7.7,2.7,12.1H68.8
            c-0.2-2.5-0.7-4.7-1.6-6.7c-0.8-2-2-3.8-3.5-5.3c-1.5-1.5-3.3-2.6-5.5-3.4c-2.1-0.8-4.6-1.2-7.4-1.2c-2.4,0-4.6,0.3-6.7,0.9
            c-2.1,0.6-3.8,1.5-5.3,2.7s-2.7,2.7-3.5,4.3c-0.8,1.7-1.3,3.6-1.3,5.8c0,2.3,0.6,4.2,1.7,5.9c1.2,1.7,2.7,3.1,4.5,4.4
            s4,2.3,6.5,3.2s5,1.8,7.6,2.5c4.1,1.2,7.9,2.7,11.6,4.4c3.7,1.8,7,3.9,9.8,6.4c2.8,2.5,5,5.3,6.7,8.6c1.6,3.3,2.5,7,2.5,11.2
            c0,4.3-0.9,8.1-2.7,11.4S78,91,74.9,93.2s-6.6,3.9-10.7,5.1c-4.1,1.2-8.3,1.7-12.7,1.7c-3,0-6-0.3-8.9-0.9
            c-2.9-0.6-5.8-1.4-8.5-2.6s-5.2-2.6-7.5-4.3s-4.3-3.7-6.1-6s-3.1-4.8-4-7.6c-1-2.8-1.5-5.9-1.5-9.2h16.1c0.2,2.9,0.9,5.5,1.9,7.7
            c1.1,2.2,2.5,4,4.3,5.5c1.8,1.4,3.9,2.5,6.3,3.3s5,1.1,7.9,1.1c2.4,0,4.7-0.3,6.8-0.9s3.9-1.4,5.5-2.6c1.6-1.1,2.8-2.5,3.7-4.2
            S68.8,75.8,68.8,73.6z`,
        T:`M88.8,13.6H58.1V100H41.7V13.6H11.2V0h77.6V13.6z`,
        U:`M82.6,0l0.1,66.1c0,5.1-0.9,9.7-2.4,13.9c-1.6,4.2-3.8,7.8-6.6,10.7c-2.8,3-6.3,5.3-10.3,6.9s-8.6,2.4-13.6,2.4
            c-4.9,0-9.3-0.8-13.3-2.4s-7.4-3.9-10.2-6.9c-2.9-3-5.1-6.5-6.7-10.7s-2.4-8.8-2.4-13.9L17.3,0h16l0.1,66.1c0,3,0.4,5.8,1.2,8.3
            s1.8,4.7,3.1,6.5c1.4,1.8,3,3.3,5,4.3s4.3,1.5,7,1.5c2.7,0,5.1-0.5,7.2-1.5c2.1-1,3.8-2.4,5.2-4.3s2.5-4,3.2-6.5s1.1-5.3,1.2-8.3
            L66.7,0H82.6z`,
        V:`M50,74.9L70.8,0h18L57.8,100H42L11.2,0h18L50,74.9z`,
        W:`M66.8,65.6L74,0h15.4L76.6,100H60.8L49.9,33.5L39.1,100H23.4L10.6,0H26l7.1,65.2L43.4,0h12.9L66.8,65.6z`,
        X:`M49.8,37.2L69,0h19.4L60,49.6L89.2,100H69.9L50.1,62.2L30.3,100H10.8L40,49.6L11.6,0h19.3L49.8,37.2z`,
        Y:`M50,47.8L70.8,0h18.4L58.1,64.1L57.9,100h-16l-0.1-36.3L10.8,0h18.4L50,47.8z`,
        Z:`M34.4,86.5h50.5V100H15.1L15,88.9L64,13.6H15.4V0h67.9l0.1,10.9L34.4,86.5z`,      
      }
    ],
    [LogoFonts.HELVETICA_BOLD, {
      A:`M68.2,79.4H31.4L24.5,100H2.6L38.3,0h23.6l35.4,100H74.7L68.2,79.4z M62.4,62.2L49.9,22.9L37,62.2H62.4z`,
      B:`M54.1,100H8.8V0h48.6c12.3,0.2,20.9,3.7,26.1,10.7C86.5,14.9,88,20,88,25.9c0,6.1-1.5,11-4.6,14.7c-1.7,2.1-4.3,4-7.6,5.7
          c5.1,1.9,9,4.8,11.6,8.8c2.6,4,3.9,8.9,3.9,14.7c0,5.9-1.5,11.2-4.5,15.9c-1.9,3.1-4.3,5.7-7.1,7.9c-3.2,2.4-7,4.1-11.4,5
          C63.9,99.5,59.2,100,54.1,100z M64,36.9c2.7-1.6,4.1-4.5,4.1-8.8c0-4.7-1.8-7.7-5.4-9.2c-3.1-1-7.1-1.6-11.9-1.6h-22v22h24.6
          C57.6,39.4,61.2,38.6,64,36.9z M53.6,56H28.7v26.7h24.6c4.4,0,7.8-0.6,10.2-1.8c4.4-2.2,6.6-6.3,6.6-12.5c0-5.2-2.1-8.8-6.4-10.7
          C61.3,56.6,57.9,56,53.6,56z`,
      C:`M79.1,90.2c-7.1,6.5-16.2,9.8-27.3,9.8c-13.7,0-24.5-4.4-32.4-13.2c-7.9-8.9-11.8-21-11.8-36.4c0-16.7,4.5-29.5,13.4-38.6
          C28.7,3.9,38.6,0,50.6,0c16.1,0,27.8,5.3,35.3,15.8c4.1,5.9,6.3,11.9,6.6,17.8h-20c-1.3-4.6-3-8-5-10.4c-3.6-4.1-9-6.2-16.1-6.2
          c-7.3,0-13,2.9-17.2,8.8C30,31.7,27.9,40,27.9,50.7c0,10.7,2.2,18.7,6.6,24.1c4.4,5.3,10.1,8,16.9,8c7,0,12.3-2.3,16-6.9
          c2-2.5,3.7-6.2,5.1-11.1h19.8C90.6,75.2,86.2,83.7,79.1,90.2z`,
      D:`M50.8,100H7.7V0h43.1C57,0.1,62.1,0.8,66.2,2.2c7,2.3,12.7,6.5,17,12.7c3.5,5,5.9,10.4,7.1,16.1c1.3,5.8,1.9,11.3,1.9,16.6
          c0,13.3-2.7,24.6-8,33.8C77.1,93.8,65.9,100,50.8,100z M66.3,25.5c-3.2-5.4-9.6-8.1-19.1-8.1H28v65.3h19.3
          c9.9,0,16.7-4.9,20.6-14.6c2.1-5.3,3.2-11.7,3.2-19.1C71.1,38.8,69.5,31,66.3,25.5z`,
      E:`M81.1,56.3H32.5V82h55.4v18H12.1V0h73.3v17.7H32.5v21.2h48.6V56.3z`,
      F:`M85.5,17.6H35.3v23h44V58h-44v42H14.5V0h71V17.6z`,
      G:`M68.7,95.2C63.5,98.4,57,100,49.4,100c-12.6,0-22.9-4.4-30.9-13.1C10,78.1,5.8,66.2,5.8,50.9c0-15.4,4.2-27.7,12.7-37
          C27,4.6,38.2,0,52.1,0c12.1,0,21.8,3.1,29.1,9.2c7.3,6.1,11.5,13.8,12.6,22.9H74.3c-1.5-6.5-5.2-11-11-13.6
          c-3.3-1.4-6.9-2.1-10.9-2.1c-7.7,0-13.9,2.9-18.9,8.7c-4.9,5.8-7.4,14.5-7.4,26.1c0,11.7,2.7,20,8,24.8c5.3,4.9,11.4,7.3,18.2,7.3
          c6.7,0,12.1-1.9,16.4-5.8c4.3-3.8,6.9-8.9,7.9-15.1h-22V46.5h39.6v51H81l-2-11.9C75.2,90.1,71.7,93.3,68.7,95.2z`,
      H:`M69.5,100V55.4H30.4V100H9.7V0h20.7v38.1h39.1V0h20.8v100H69.5z`,
      I:`M39.6,0h20.8v100H39.6V0z`,
      J:`M60.9,68.3V0h20.4v67.9c0,8.3-1.4,14.9-4.3,19.7C72.1,95.9,63.4,100,50.7,100c-12.7,0-21.2-3.3-25.5-10
          c-4.3-6.6-6.5-15.7-6.5-27.2v-2.3H38v2.3c0.2,7.6,1,12.9,2.5,15.9c1.5,3.1,4.6,4.6,9.5,4.6c4.8,0,8-1.7,9.5-5
          C60.4,76.4,60.9,73,60.9,68.3z`,
      K:`M94.1,100H67.3L36.4,56.3l-10,10.2V100H5.9V0h20.6v41.2L65,0h27l-41,41.2L94.1,100z`,
      L:`M14.8,0h20.9v82h49.5v18H14.8V0z`,
      M:`M21.1,100H1.7V0h30.4l18.2,78.6L68.3,0h30.1v100H78.9V32.4c0-1.9,0-4.7,0.1-8.2c0-3.5,0.1-6.2,0.1-8.1L60.1,100H39.8
          L21,16.1c0,1.9,0,4.6,0.1,8.1c0,3.5,0.1,6.2,0.1,8.2V100z`,
      N:`M90.6,100H69.7L28.9,29v71H9.4V0h21.9l39.8,69.8V0h19.5V100z`,
      O:`M50,100c-13.5,0-23.8-3.7-31-11C9.5,80,4.7,67,4.7,50c0-17.3,4.8-30.3,14.4-39C26.2,3.7,36.5,0,50,0c13.5,0,23.8,3.7,31,11
          c9.5,8.7,14.3,21.7,14.3,39c0,17-4.8,30-14.3,39C73.9,96.3,63.5,100,50,100z M68.7,74.6c4.6-5.8,6.9-14,6.9-24.6
          c0-10.6-2.3-18.8-6.9-24.6c-4.6-5.8-10.8-8.7-18.6-8.7S36,19.6,31.3,25.4c-4.7,5.8-7,14-7,24.6s2.3,18.8,7,24.6
          c4.7,5.8,10.9,8.7,18.7,8.7S64.1,80.4,68.7,74.6z`,
      P:`M53.7,64H32.5v36H11.7V0h43.6c10,0,18,2.6,24,7.7c6,5.2,9,13.1,9,23.9c0,11.8-3,20.1-9,25C73.3,61.6,64.8,64,53.7,64z
          M63.5,43.2c2.7-2.4,4.1-6.2,4.1-11.4c0-5.2-1.4-8.9-4.1-11.1c-2.7-2.2-6.6-3.3-11.5-3.3H32.5v29.4H52
          C56.9,46.8,60.7,45.6,63.5,43.2z`,
      Q:`M64.2,93.9c-4.1,1.4-8.9,2-14.6,2c-11.8,0-21.6-3.5-29.3-10.6c-9.3-8.5-14-21-14-37.4c0-16.6,4.8-29.1,14.4-37.6
          C28.6,3.5,38.3,0,49.9,0c11.7,0,21.6,3.7,29.5,11c9.2,8.5,13.8,20.4,13.8,35.6c0,8.1-1,14.8-3,20.3c-1.6,5.2-4,9.5-7.1,13l10.5,9.8
          L83.8,100L72.8,89.7C69.5,91.7,66.6,93.1,64.2,93.9z M60.1,77.6L51,68.8l9.8-10.2l9.2,8.7c1.4-3,2.4-5.5,3-7.7
          c0.9-3.3,1.4-7.2,1.4-11.6c0-10.2-2.1-18-6.2-23.6c-4.2-5.6-10.2-8.3-18.2-8.3c-7.5,0-13.5,2.7-18,8c-4.5,5.3-6.7,13.3-6.7,23.9
          c0,12.4,3.2,21.3,9.6,26.7c4.1,3.5,9.1,5.2,14.9,5.2c2.2,0,4.3-0.3,6.3-0.8C57,78.8,58.4,78.3,60.1,77.6z`,
      R:`M50.3,60.8H28.7V100H8.3V0h49c7,0.1,12.4,1,16.2,2.6c3.8,1.6,7,3.9,9.6,7c2.2,2.5,3.9,5.3,5.2,8.4
          c1.3,3.1,1.9,6.6,1.9,10.5c0,4.7-1.2,9.4-3.6,14c-2.4,4.6-6.4,7.8-11.9,9.7c4.6,1.9,7.9,4.5,9.8,7.9c1.9,3.4,2.9,8.6,2.9,15.6v6.7
          c0,4.6,0.2,7.7,0.5,9.3c0.5,2.6,1.8,4.5,3.8,5.7v2.5h-23c-0.6-2.2-1.1-4-1.4-5.4c-0.5-2.8-0.8-5.7-0.9-8.6l-0.1-9.3
          c-0.1-6.4-1.3-10.6-3.5-12.8C60.6,61.8,56.4,60.8,50.3,60.8z M63,42.6c4.2-1.9,6.2-5.7,6.2-11.3c0-6.1-2-10.1-6-12.2
          c-2.3-1.2-5.7-1.8-10.2-1.8H28.7v26.9h23.7C57.1,44.2,60.6,43.7,63,42.6z`,
      S:`M50.1,83.9c4.7,0,8.5-0.5,11.4-1.5c5.5-2,8.3-5.6,8.3-11c0-3.1-1.4-5.6-4.1-7.3c-2.7-1.7-7.1-3.2-12.9-4.4l-10-2.3
          c-9.9-2.2-16.7-4.7-20.5-7.3c-6.4-4.4-9.6-11.2-9.6-20.5c0-8.5,3.1-15.5,9.3-21.2C28.1,2.8,37.2,0,49.1,0c10,0,18.5,2.6,25.6,7.9
          c7.1,5.3,10.7,13,11.1,23.1h-19c-0.3-5.7-2.8-9.8-7.5-12.2c-3.1-1.6-6.9-2.4-11.5-2.4c-5.1,0-9.2,1-12.2,3.1
          c-3,2.1-4.6,4.9-4.6,8.6c0,3.4,1.5,5.9,4.5,7.6c1.9,1.1,6,2.4,12.3,3.9l16.3,3.9c7.2,1.7,12.6,4,16.2,6.9
          c5.7,4.5,8.5,10.9,8.5,19.4c0,8.7-3.3,15.9-9.9,21.6c-6.6,5.7-16,8.6-28.1,8.6c-12.3,0-22.1-2.8-29.1-8.5
          c-7.1-5.6-10.6-13.4-10.6-23.2H30c0.6,4.3,1.8,7.6,3.5,9.7C36.8,81.9,42.3,83.9,50.1,83.9z`,
      T:`M90.5,0v17.7H60.6V100h-21V17.7H9.5V0H90.5z`,
      U:`M68.5,59.9V0h20.7v59.9c0,10.4-1.6,18.4-4.8,24.2C78.4,94.7,66.9,100,50,100c-16.9,0-28.4-5.3-34.4-15.9
          c-3.2-5.8-4.8-13.8-4.8-24.2V0h20.7v59.9c0,6.7,0.8,11.6,2.4,14.7c2.5,5.5,7.8,8.2,16.1,8.2c8.2,0,13.6-2.7,16.1-8.2
          C67.7,71.5,68.5,66.6,68.5,59.9z`,
      V:`M6.2,0h22.3l21.7,75.9L72.1,0h21.7L59.7,100H40L6.2,0z`,
      W:`M83.3,0H100L77.8,100H62.1l-9.5-58.5l-2.8-19.3l-2.8,19.3L37.6,100H22.3L0,0h17.5l10.4,57.3l2.2,15.9l2.3-15.6L41.3,0h17.4
          L68,57.3l2.4,15.9l2.4-15.3L83.3,0z`,
      X:`M94.4,100H69L50,65.9L29.9,100H5.6l32.2-50.9L7.2,0h25L50,32.5L68.3,0h24.2L61.9,48.2L94.4,100z`,
      Y:`M94.5,0L61,62.4V100H40.1V62.4L5.5,0h24.6l20.8,43.6L70.8,0H94.5z`,
      Z:`M88.9,100H11.1V82.4l51.6-64.7H12.4V0h76.4v16.8L36.5,82.4h52.4V100z`,

    }]
  ])
  
}
